const _ = require('lodash')
import React, { useState, useEffect } from 'react'
import { Button, Modal, Form, Col, Row } from 'react-bootstrap'
import NotificationAlert from 'react-notification-alert'

import { putData } from 'utils/server'
import { getAuthorizationHeader, notificationOptions } from 'utils/helpers'
import SportSelect from 'components/Inputs/SportSelect'
import RegionSelect from 'components/Inputs/RegionSelect'

function UpdateUniComps({ showModal, signalCloseModal, competition, sports, regions, sport, region }) {
  const notificationAlertRef = React.useRef(null)

  const [show, setShow] = useState(false)

  const [compNameChanged, setCompNameChanged] = useState(false)

  const [selectedSport, setSelectedSport] = useState({})
  const [selectedRegion, setSelectedRegion] = useState({})
  const [selectedSort, setSelectedSort] = useState({})
  const [compEsportChanged, setCompEsportChanged] = useState(false)
  const [isChanged, setIsChanged] = useState(false)

  const handleNameChange = selectedItem => {
    setCompNameChanged(selectedItem.target.value)
    setIsChanged(true)
  }

  const handleEsportChange = selectedItem => {
    let value = selectedItem.target.checked ?? false;
    setCompEsportChanged(value);
    setIsChanged(true)
  }

  const handleSportChange = selectedItem => {
    setSelectedSport(selectedItem)
    setIsChanged(true)
  }

  const handleRegionChange = selectedItem => {
    setSelectedRegion(selectedItem)
    setIsChanged(true)
  }

  const handleSortChange = selectedItem => {
    setSelectedSort(selectedItem.target.value)
    setIsChanged(true)
  }

  const handleClose = () => {
    setShow(false)
    setIsChanged(false)
    signalCloseModal(false, null)
  }

  const handleSubmit = async () => {
    let compId = document.getElementById('competition-id').value
    let compName = document.getElementById('competition-name').value
    let compSort = document.getElementById('sort').value

    compSort = compSort === '' ? null : compSort
    if (compSort !== null && /[^0-9]/.test(compSort)) {
      notificationAlertRef.current.notificationAlert(notificationOptions('Error! Cannot Update.', 'danger'))
      return
    }
    const competition = {
      name: compName,
      region: selectedRegion.name,
      sport: selectedSport.name,
      sport_id: selectedSport.id,
      region_id: selectedRegion.id,
      id: compId,
      sort: parseInt(compSort),
      is_esport: compEsportChanged
    }

    //console.log(competition)
    let response = await putData(`/api/unis/competitions/${compId}`, competition, { Authorization: getAuthorizationHeader() })
    if (response == null) {
      notificationAlertRef.current.notificationAlert(notificationOptions('Error! Cannot Update.', 'danger'))
      return
    }
    notificationAlertRef.current.notificationAlert(notificationOptions('Success! Updated.', 'success'))
    setShow(false)
    signalCloseModal(true, competition)
  }

  useEffect(() => {
    setShow(showModal)
    setSelectedSport({ name: competition.sport, id: competition.sport_id })

    setSelectedRegion({ name: competition.region, id: competition.region_id })
    setCompNameChanged(competition.name)
    setCompEsportChanged(competition.is_esport)
  }, [showModal])
  return (
    <>
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md="12"   style={{ marginTop: "-1.5rem", display: "flex", justifyContent: "center", alignItems: "center"}}>
                <h4><label>Uni Competitions Settings</label></h4>
              </Col>
              <Col md="12">
                <Form.Control id="competition-id" name="competition-id" value={competition.id} plaintext="true" type="hidden" readOnly></Form.Control>
                <Form.Group style={{ marginTop:"0.5rem" }}>
                  <label>Competition Name:</label>
                  <Form.Control id="competition-name" name="competition-name" type="text" placeholder="Type Competition Name" defaultValue={competition.name} onChange={handleNameChange}></Form.Control>
                </Form.Group>
              </Col>
              <Col md="12">
                <Form.Group style={{ marginTop:"0.5rem" }}>
                  <label>Sport:</label>
                  <SportSelect sports={sports} selectedItem={sport} updateSport={handleSportChange} />
                </Form.Group>
              </Col>
              <Col md="12">
                <Form.Group style={{ marginTop:"0.5rem" }}>
                  <label>Region:</label>
                  <RegionSelect regions={regions} selectedItem={region} updateRegion={handleRegionChange} />
                </Form.Group>
              </Col>
              <Col md="12">
                <Form.Group style={{ marginTop:"0.5rem" }}>
                  <label>Sort</label>
                  <Form.Control id="sort" name="sort" type="text" placeholder="Type Sort" defaultValue={competition.sort} onChange={handleSortChange}></Form.Control>
                </Form.Group>
              </Col>
              <Col md="12">
              <Form.Group className="d-flex align-items-center" style={{ marginTop:"0.5rem" }}>
                  <label>Is Esport:</label>
                  <Form.Check id="competition-esport" name="competition-esport"  style={{ marginTop:"-1.67rem" }} defaultChecked={competition.is_esport === true ? true : false} className="mb-1 pl-4" onChange={handleEsportChange} />
              </Form.Group>
            </Col>
            </Row>
          </Form>
          <br />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="warning" style={{ color: isChanged === true ? "" : "#ff9500", cursor: isChanged ? "pointer" : "not-allowed" }} disabled={isChanged === false} onClick={handleSubmit}>
            Update Competition
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default UpdateUniComps
