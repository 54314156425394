import React, { useEffect, useState } from 'react'

import Select from 'react-select'

function RegionSelect({ regions, selectedItem, updateRegion }) {
  const [initItem, setInitItem] = useState(null)
  const handleChange = selectedItem => {
    updateRegion(selectedItem)
    setInitItem(selectedItem)
  }
  regions.map(el => {
    el.value = el.id
    el.label = el.name
  })

  useEffect(() => {
    if (selectedItem) {
      let item = null
      if (typeof selectedItem == 'string') item = JSON.parse(selectedItem)
      if (typeof selectedItem == 'object') item = selectedItem
      if (item) {
        //console.log('RegionSelect->useEffect->', item)
        setInitItem(item)
      }
    }
  }, [selectedItem])

  return <Select className="region-select" name="region-select" id="region-select" value={initItem} options={regions} placeholder="Select Region.." onChange={handleChange} />
}

export default RegionSelect
