const _ = require('lodash')
import React, { useState, useEffect } from 'react'
import { Button, Modal, Form, Col, Row } from 'react-bootstrap'
import NotificationAlert from 'react-notification-alert'

import { putData } from 'utils/server'
import { getAuthorizationHeader, notificationOptions } from 'utils/helpers'

function UpdateComps({ showModal, competition, signalCloseModal }) {
  const notificationAlertRef = React.useRef(null)

  const [show, setShow] = useState(false)
  const [compExcludedChanged, setCompExcludedChanged] = useState(false)
  const [compHomeDefaultChanged, setCompHomeDefaultChanged] = useState(false)
  const [compEsportChanged, setCompEsportChanged] = useState(false)
  const [isAnyCheckboxClicked, setIsAnyCheckboxClicked] = useState(false)

  const handleExluded = selectedItem => {
    let value = selectedItem.target.checked ?? false;
    setCompExcludedChanged(value);
    setIsAnyCheckboxClicked(true);
  }

  const handleHomeDefaultChange = selectedItem => {
    let value = selectedItem.target.checked ?? false;
    setCompHomeDefaultChanged(value);
    setIsAnyCheckboxClicked(true);
  }

  const handleEsportChange = selectedItem => {
    let value = selectedItem.target.checked ?? false;
    setCompEsportChanged(value);
    setIsAnyCheckboxClicked(true);
  }

  const handleClose = () => {
    setShow(false);
    setIsAnyCheckboxClicked(false);
    signalCloseModal(false, null);
  }

  const handleSubmit = async () => {
    const competitionData = {
      pid: competition.pid,
      provider_id: competition.provider_id,
      sport_id: competition.sport_id,
      ...(isAnyCheckboxClicked && compExcludedChanged !== competition.isExcluded && { is_excluded: compExcludedChanged }),
      ...(isAnyCheckboxClicked && compHomeDefaultChanged !== competition.home_default && { is_home_default: compHomeDefaultChanged }),
      ...(isAnyCheckboxClicked && compEsportChanged !== competition.is_esport && { is_esport: compEsportChanged })
    }

    let response = await putData(`/api/providers/competitions/${competition.id}/settings`, competitionData, { Authorization: getAuthorizationHeader() })
    if (response == null) {
      notificationAlertRef.current.notificationAlert(notificationOptions('Error! Cannot Update.', 'danger'))
      return
    }
    notificationAlertRef.current.notificationAlert(notificationOptions('Success! Updated.', 'success'))
    setShow(false)
    signalCloseModal(true, competition)
  }

  useEffect(() => {
    setShow(showModal)
    setCompExcludedChanged(competition.isExluded)
    setCompHomeDefaultChanged(competition.home_default)
    setCompEsportChanged(competition.is_esport)
  }, [showModal])

  return (
    <>
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md="12" style={{ marginTop: "-1.5rem", display: "flex", justifyContent: "center", alignItems: "center"}}>
                <h4><label>Provider Competitions Settings</label></h4>
              </Col>
              <Col md="12">
                <Form.Group style={{ marginTop:"0.5rem" }}>
                    <label>Competition Name:</label>
                    <Form.Control id="competition-name" name="competition-name" type="text" disabled defaultValue={competition.name} ></Form.Control>
                </Form.Group>
              </Col>
              <Col md="12">
                <Form.Group className="d-flex align-items-center" style={{ marginTop:"0.5rem" }}>
                  <label>Is Excluded:</label>
                  <Form.Check id="competition-excluded" name="competition-excluded" style={{ marginTop:"-1.67rem" }} defaultChecked={competition.isExcluded ?? false}  className="mb-1 pl-4" onChange={handleExluded} />
                </Form.Group>
              </Col>
              <Col md="12">
                <Form.Group className="d-flex align-items-center" style={{ marginTop:"0.5rem" }}>
                  <label>Home Default:</label>
                  <Form.Check id="competition-default" name="competition-home-default" style={{ marginTop:"-1.67rem" }} defaultChecked={competition.home_default ?? false } className="mb-1 pl-4" onChange={handleHomeDefaultChange} />
                </Form.Group>
              </Col>
              <Col md="12">
                <Form.Group className="d-flex align-items-center" style={{ marginTop:"0.5rem" }}>
                  <label>Is Esport:</label>
                  <Form.Check id="competition-esport" name="competition-esport" style={{ marginTop:"-1.67rem" }} defaultChecked={competition.is_esport ?? false} className="mb-1 pl-4" onChange={handleEsportChange} />
                </Form.Group>
              </Col>
            </Row>
          </Form>
          <br />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="warning" style={{ color: isAnyCheckboxClicked === true ? "" : "#ff9500", cursor: isAnyCheckboxClicked ? "pointer" : "not-allowed" }} disabled={isAnyCheckboxClicked === false} onClick={handleSubmit}>
            Update Competition
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default UpdateComps
