const _ = require('lodash')
import React, { useEffect, useState } from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import filterFactory, { textFilter, selectFilter, Comparator, numberFilter } from 'react-bootstrap-table2-filter'
import cellEditFactory from 'react-bootstrap-table2-editor'
import NotificationAlert from 'react-notification-alert'
import { Button, ButtonGroup, Card, Row, Col, Container } from 'react-bootstrap'
import CompetitionUpdate from 'components/Modals/UpdateComps'

import { getData, putData, delData } from 'utils/server'
import { getAuthorizationHeader, notificationOptions } from 'utils/helpers'
import TableCounters from 'components/UniObject/TableCounters'
import DateSelect from 'components/Inputs/DateSelect'
import CustomSelect from 'components/Inputs/CustomSelect'

import Swal from 'sweetalert2'
import context from 'react-bootstrap/esm/AccordionContext'
let customFilterFunc
let regionFilter = ''

function ProviderComps({ providerId, sports, informParent, forceUpdate, informresults }) {
  const controller = new AbortController()
  var pos1 = 0,
    pos2 = 0,
    pos3 = 0,
    pos4 = 0
  const notificationAlertRef = React.useRef(null)
  const [allTableData, setAllData] = useState([])
  const [tableData, setData] = useState([])
  const [allcounter, setAllCounter] = useState(0)
  const [filteredcounter, setFilteredcounter] = useState(0)
  const [sportFilters, setSportFilters] = useState({})
  const [regionFilters, setRegionFilters] = useState({})

  const [matched, setMatched] = useState(false)
  const [filterAll, setFilterAll] = useState(true)
  const [filteredcopy, setFilteredcopy] = useState({})
  const [filteredcopyParticipants, setFilteredcopyParticipants] = useState({})
  const [disabled, setDisabled] = useState(true)
  const [disabledparticipants, setDisabledparticipants] = useState(true)
  const [prevdisabled, setPrevDisabled] = useState(true)
  const [prevdisabledparticipants, setPrevDisabledParticipants] = useState(true)
  const [ClickedPrepareCopy, setClickedPrepareCopy] = useState(false)
  const [ClickedPrepareCopyParticipants, setClickedPrepareCopyParticipants] = useState(false)
  const [successResults, setSuccessResults] = useState([])
  const [successResultsParticipants, setSuccessResultsParticipants] = useState([])
  const [ResultsModal, setResultsModal] = useState(false)
  const [ResultsModalParticpant, setResultsModalParticpant] = useState(false)
  const [copied, setCopied] = useState(false)
  const [copiedParticipants, setCopiedParticipants] = useState(false)
  const [errorResults, setErrorResults] = useState([])
  const [errorResultsParticipants, setErrorResultsParticipants] = useState([])
  const [operators, setOperators] = useState({ like: Comparator.LIKE, equal: Comparator.EQ, greater: Comparator.GT, less: Comparator.LT })
  const [selectedOperator, setSelectedOperator] = useState('>')
  const [selectedDate, setSelectedDate] = useState(1)
  const [selectedSport, setSelectedSport] = useState({})
  const [selectedRegion, setSelectedRegion] = useState({})
  const [Selectedpid, setSelectedpid] = useState({})
  const [selectedName, setSelectedName] = useState({})
  const [sportFilter, setSportFilter] = useState('')
  const [isPrepareCopyActive, setIsPrepareCopyActive] = useState(false)
  const [isPrepareCopyParticipantsActive, setIsPrepareCopyParticipantsActive] = useState(false)
  const [selectedCompetition, setSelectedCompetition] = useState({})
  const [showUpdateModal, setShowUpdateModal] = useState(false)

  const customFilter = numberFilter({
    placeholder: 'My Custom PlaceHolder', // custom the input placeholder
    className: 'my-custom-text-filter', // custom classname on input
    defaultValue: { number: 1, comparator: selectedOperator }, // default filtering value
    // comparator: operators[selectedOperator], // default is Comparator.LIKE
    comparators: [Comparator.EQ, Comparator.GT, Comparator.LT],
    caseSensitive: false, // default is false, and true will only work when comparator is LIKE
    style: { display: 'inline-grid' }, // custom the style on number filter
    className: 'custom-numberfilter-class', // custom the class on number filter
    comparatorStyle: { backgroundColor: 'antiquewhite', visibility: 'hidden', display: 'none' }, // custom the style on comparator select
    comparatorClassName: 'custom-comparator-class', // custom the class on comparator select
    numberStyle: { backgroundColor: 'cadetblue', margin: '0px', visibility: 'hidden', display: 'none' }, // custom the style on number input/select
    numberClassName: 'custom-number-class', // custom the class on ber input/select
    delay: 500, // how long will trigger filtering after user typing, default is 500 ms
    getFilter: filter => (customFilterFunc = filter), // accept callback function and you can call it for filter programmtically
    id: 'uni_id' // assign a unique value for htmlFor attribute, it's useful when you have same dataField across multiple table in one page
  })

  function customExternalFilter(func, data) {
    if (func) func(data)
  }

  const paginationOptions = {
    disablePageTitle: true,
    showTotal: false,
    sizePerPageList: [
      {
        text: '25',
        value: 20
      },
      {
        text: '50',
        value: 50
      }
    ]
  }

  const columns = [
    {
      dataField: 'pid',
      text: 'P-ID',
      editable: false,
      headerStyle: { width: '5vw' },
      sort: true,
      filter: textFilter()
    },
    {
      dataField: 'sport_id',
      text: 'Sport',
      editable: false,
      headerStyle: { width: '5vw' },
      formatter: cell => sportFilters[cell],
      filter: selectFilter({
        options: sportFilters,
        getFilter: filter => {
          // Store the filter function in the ref
          setSportFilter(filter)
        }
      }),
      sort: true
    },
    {
      dataField: 'region',
      text: 'Region',
      editable: false,
      headerStyle: { width: '5vw' },
      filter: textFilter(),
      // formatter: cell => regionFilters[cell],
      // filter: selectFilter({
      //   options: regionFilters,
      //   getFilter: filter => {
      //     regionFilter = filter
      //   }
      // }),
      sort: true
    },
    {
      dataField: 'name',
      text: 'name',
      filter: textFilter(),
      editable: false,
      sort: true
    },
    {
      dataField: 'created_tmstmp',
      text: 'Created',
      filter: customFilter,
      editable: false,
      sort: true,
      headerStyle: { width: '5vw' },
      formatter: (cell, row) => {
        return (
          <div className="text-center" style={{ margin: '24px 0px 24px', fontSize: '0.85em' }}>
            {row.created.split('.')[0].split('T')[0] + ', ' + row.created.split('.')[0].split('T')[1]}
          </div>
        )
      }
    },
    {
      dataField: 'created',
      text: 'Created',
      filter: textFilter(),
      editable: false,
      sort: true,
      headerStyle: { width: '5vw' },
      hidden: true,
      formatter: (cell, row) => (
        <div className="text-center" style={{ margin: '24px 0px 24px', fontSize: '0.85em' }}>
          {cell.split('.')[0].split('T')[0] + ', ' + cell.split('.')[0].split('T')[1]}
        </div>
      )
    },
    {
      dataField: 'uni_id',
      text: 'Uni Id',
      headerStyle: { width: '5vw' },
      validator: newValue => {
        if (newValue != '' && !_.isInteger(parseInt(newValue))) {
          return {
            valid: false,
            message: 'Invalid Uni Id'
          }
        }
      },
      filter: textFilter(),
      sort: true
    },

    {
      dataField: 'dm1',
      isDummyField: true,
      editable: false,
      headerStyle: { width: '4.5vw' },
      style: { padding: '0px' },
      text: 'Actions',
      formatter: (cellContent, row) =>
        !row.uni_id ? (
          <div className="actions">
            <Button
              title="Edit competition"
              className="btn-simple btn-link p-1"
              type="button"
              variant="primary"
              onClick={() => {
                handleEditClick(row)
              }}          
              >
              <i className="fas fa-edit"></i>
            </Button>
            <Button
              title="Clone Competition"
              className="btn-simple btn-link p-1"
              type="button"
              variant="info"
              onClick={() => {
                handleCloneClick(row)
              }}
            >
              <i className="fas fa-clone"></i>
            </Button>
            <Button
              title="Clone Participants"
              className="btn-simple btn-link p-1"
              type="button"
              variant="warning"
              onClick={() => {
                handleCloneParticipantsClick(row)
              }}
            >
              <i className="fas fa-users"></i>
            </Button>
            <Button
              title="Show Fixtures"
              className="btn-simple btn-link p-1"
              type="button"
              variant="success"
              onClick={() => {
                handleFixturesClick(row)
              }}
            >
              <i className="fas fa-tasks"></i>
            </Button>
            <Button
              title="Show Participants"
              className="btn-simple btn-link p-1"
              type="button"
              onClick={() => {
                handleParticipantsInfo(row)
              }}
            >
              <i className="fas fa-user"></i>
            </Button>
          </div>
        ) : (
          <div className="actions">
            <Button
              title="Update Competition"
              className="btn-simple btn-link p-1"
              type="button"
              variant="primary"
              onClick={() => {
                handleEditClick(row)
              }}          
              >
              <i className="fas fa-edit"></i>
            </Button>
            <Button
              title="Copy Participant"
              className="btn-simple btn-link p-1"
              type="button"
              variant="warning"
              onClick={() => {
                handleCloneParticipantsClick(row)
              }}
            >
              <i className="fas fa-users"></i>
            </Button>
            <Button
              title="Show Fixtures"
              className="btn-simple btn-link p-1"
              type="button"
              variant="success"
              onClick={() => {
                handleFixturesClick(row)
              }}
            >
              <i className="fas fa-tasks"></i>
            </Button>
            <Button
              title="Show Participants"
              className="btn-simple btn-link p-1"
              type="button"
              onClick={() => {
                handleParticipantsInfo(row)
              }}
            >
              <i className="fas fa-user"></i>
            </Button>
          </div>
        )
    }
  ]

  const handleParticipantsInfo = row => {
    informParent({ participants: row })
  }

  const handleEditClick = row => {
    setSelectedCompetition(row)
    showUpdateModal ? setShowUpdateModal(false) : setShowUpdateModal(true)
  }

  const handleUpdateCompetitionCloseModal = update => {
    setShowUpdateModal(false)
    if (update) {
      fetchData(controller)
      //informParent({ refresh: true })
    }
  }

  const handleCopyParticipants = async () => {
    //handleFilterAll()
    setIsPrepareCopyParticipantsActive(false)
    // console.log(filteredcopyParticipants)

    Swal.fire({
      title: `You are going to Copy Participants. \nAre you sure?`,
      showDenyButton: true,
      showConfirmButton: true,
      confirmButtonText: 'Yes, Copy it!',
      denyButtonText: 'Cancel',
      icon: 'warning',
      confirmButtonColor: '#87cb16',
      denyButtonColor: '#ff4a55'
    }).then(async result => {
      if (result.isConfirmed) {
        // Iterate over each item in the filteredcopy array

        await putData('/api/providers/competitions/multitransferparticipants', { competitions: filteredcopyParticipants }, { Authorization: getAuthorizationHeader() }, errorHandler).then(async response => {
          if (response == null) {
            notificationAlertRef.current.notificationAlert(notificationOptions('Error! Cannot Copy record.', 'danger'))
          } else {
            setCopiedParticipants(true)

            const results = response.data
            setPrevDisabledParticipants(false)
            setSuccessResultsParticipants(results.success)

            setErrorResultsParticipants(results.error)

            notificationAlertRef.current.notificationAlert(notificationOptions('Check the results.', 'success'))
            // Note: Consider fetching data and informing parent outside the loop if these actions should occur once after all operations
          }
        })

        fetchData(controller) // Fetch data after all operations
        informParent({ refresh: true }) // Inform the parent to refresh after all operations
        return { async: true }
      }
    })
  }
  const handleCopy = async () => {
    //handleFilterAll()
    setIsPrepareCopyActive(false)

    Swal.fire({
      title: `You are going to Copy Competitions. \nAre you sure?`,
      showDenyButton: true,
      showConfirmButton: true,
      confirmButtonText: 'Yes, Copy it!',
      denyButtonText: 'Cancel',
      icon: 'warning',
      confirmButtonColor: '#87cb16',
      denyButtonColor: '#ff4a55'
    }).then(async result => {
      if (result.isConfirmed) {
        // Iterate over each item in the filteredcopy array

        await putData('/api/providers/competitions/multitransfer', { competitions: filteredcopy }, { Authorization: getAuthorizationHeader() }, errorHandler).then(async response => {
          if (response == null) {
            notificationAlertRef.current.notificationAlert(notificationOptions('Error! Cannot Copy record.', 'danger'))
          } else {
            setCopied(true)

            const results = response.data
            setPrevDisabled(false)
            setSuccessResults(results.success)

            setErrorResults(results.error)

            notificationAlertRef.current.notificationAlert(notificationOptions('Success! Record has been copied.', 'success'))
            // Note: Consider fetching data and informing parent outside the loop if these actions should occur once after all operations
          }
        })

        fetchData(controller) // Fetch data after all operations
        informParent({ refresh: true }) // Inform the parent to refresh after all operations
        return { async: true }
      }
    })
  }
  const handleprev = async () => {
    setResultsModal(true)
    setCopied(false)
  }

  const handleprevparticipants = async () => {
    setResultsModalParticpant(true)
    setCopiedParticipants(false)
  }
  const handleCloneClick = async row => {
    Swal.fire({ title: `You are going to Copy Competition,  \n"${row.name}". \nAre you sure?`, showDenyButton: true, showConfirmButton: true, confirmButtonText: 'Yes, Copy it!', denyButtonText: 'Cancel', icon: 'warning', confirmButtonColor: '#87cb16', denyButtonColor: '#ff4a55' }).then(async result => {
      if (result.isConfirmed) {
        if (!row.provider_id || !row.pid) {
          notificationAlertRef.current.notificationAlert(notificationOptions('Error! Cannot Copy record. Missing data!', 'danger'))
          return
        }
        await putData('/api/providers/competitions/transfer', { competitionId: row.pid, providerId: row.provider_id, sportId: row.sport_id, sort: 100000 }, { Authorization: getAuthorizationHeader() }, errorHandler).then(async response => {
          if (response == null) {
            notificationAlertRef.current.notificationAlert(notificationOptions('Error! Cannot Copy record.', 'danger'))
          } else {
            notificationAlertRef.current.notificationAlert(notificationOptions('Success! Record has been copied.', 'success'))
            fetchData(controller)
            informParent({ refresh: true })
          }
        })
        return { async: true }
      }
    })
  }

  const handleCloneParticipantsClick = async row => {
    Swal.fire({ title: `You are going to Copy Participants from Competition, \n"${row.name}". \nAre you sure?`, showDenyButton: true, showConfirmButton: true, confirmButtonText: 'Yes, Copy it!', denyButtonText: 'Cancel', icon: 'warning', confirmButtonColor: '#87cb16', denyButtonColor: '#ff4a55' }).then(async result => {
      if (result.isConfirmed) {
        if (!row.provider_id || !row.pid) {
          notificationAlertRef.current.notificationAlert(notificationOptions('Error! Cannot Copy record. Missing data!', 'danger'))
          return
        }
        await putData('/api/providers/competitions/transferparticipants', { competitionId: row.pid, providerId: row.provider_id, sportId: row.sport_id, sportName: row.sport }, { Authorization: getAuthorizationHeader() }, errorHandler).then(async response => {
          if (response == null) {
            notificationAlertRef.current.notificationAlert(notificationOptions('Error! Cannot Copy Participants.', 'danger'))
          } else {
            notificationAlertRef.current.notificationAlert(notificationOptions('Success! Participants have been copied.', 'success'))
          }
        })
        return { async: true }
      }
    })
  }

  const handleFixturesClick = row => {
    informParent({ fixtures: row })
  }

  const errorHandler = err => {
    let errorToShow = err.name + ': ' + err.message
    if (err.response && err.response.data) errorToShow = errorToShow + ' (' + err.response.data + ')'

    notificationAlertRef.current.notificationAlert(notificationOptions('Error info: ' + errorToShow, 'danger'))
  }

  function clearAllFilter() {
    //regionFilter('')
  }

  const sleep = ms =>
    new Promise(resolve => {
      setTimeout(resolve, ms)
    })

  const fetchData = async controller => {
    if (providerId == null || providerId == 0) return
    clearAllFilter()

    let response = await getData(`/api/providers/${providerId}/competitions`, { Authorization: getAuthorizationHeader() }, null, { signal: controller.signal })
    //let excluded = await getData(`/api/providers/competitions/excludedd`, { Authorization: getAuthorizationHeader() }, null, { signal: controller.signal })

    let tableData = []
    let regionFilters = {}
    let regionIds = []

    for (let index = 0; index < response.data.length; index++) {
      const competition = response.data[index]
      if (!regionIds.find(item => item.region_id === competition.region_id)) {
        regionIds.push({ region_id: competition.region, region: competition.region })
      }
    }

    regionIds = regionIds.sort((a, b) => (a.region.toLowerCase() > b.region.toLowerCase() ? 1 : -1))

    for (let index = 0; index < regionIds.length; index++) {
      const region = regionIds[index]
      regionFilters[region.region_id] = region.region
    }

    setRegionFilters(regionFilters)

    if (response != null) tableData = response.data

    tableData = tableData.map(item => ({
      ...item,

      isExcluded: item.is_excluded === 0,
      // Convert the created property to a timestamp
      created_tmstmp: new Date(item.created).getTime()
    }))

    tableData.sort((a, b) => (a.created < b.created ? 1 : -1))

    setAllCounter(response.data.length)
    setAllData(tableData)
    setData(tableData)

    let allfitlerPrev = filterAll
    let matchedPrev = matched

    sleep(5000)
    await preFilterData(allfitlerPrev, matchedPrev, tableData)
  }

  const changeCellValue = async (oldValue, newValue, row, column, done) => {
    row.uni_id = newValue == '' ? null : parseInt(newValue)

    let rowData = { ...row }
    delete rowData.created_tmstmp

    await putData(`/api/providers/competitions/${row.id}`, rowData, { Authorization: getAuthorizationHeader() }, errorHandler).then(async response => {
      if (response == null) {
        notificationAlertRef.current.notificationAlert(notificationOptions('Error! Cannot edit record.', 'danger'))
        fetchData(controller)
        done(false)
      } else {
        done(true)
        let allfitlerPrev = filterAll
        let matchedPrev = matched
        sleep(5000)
        await preFilterData(allfitlerPrev, matchedPrev)
      }
    })
    return { async: true }
  }

  const prefiltercopy = async () => {
    sleep(2000)
    setCopied(false)
    // Initialize filter variables
    let selectedname = selectedName ? selectedName.filterVal.toLowerCase() : ''
    let selectedpid = Selectedpid ? parseInt(Selectedpid.filterVal, 10) : null
    let selectedregion = selectedRegion ? selectedRegion.filterVal.toLowerCase() : ''
    let selectedid = selectedSport.filterVal
    let selecteddate = selectedDate
    let comps = []
    let competitions = []

    for (let comp of tableData) {
      let includeComp = true

      // Existing conditions
      if (selectedDate == 1) {
        if (!(comp.sport_id == selectedid && comp.is_excluded != 0 && comp.uni_id == null)) {
          includeComp = false
        }
      } else {
        if (selectedOperator == '>') {
          if (!(comp.sport_id == selectedid && comp.is_excluded != 0 && comp.uni_id == null && comp.created_tmstmp > selecteddate)) {
            includeComp = false
          }
        } else {
          // selectedOperator == '<'
          if (!(comp.sport_id == selectedid && comp.is_excluded != 0 && comp.uni_id == null && comp.created_tmstmp < selecteddate)) {
            includeComp = false
          }
        }
      }

      // Additional condition for selectedName
      if (selectedname && !comp.name.toLowerCase().includes(selectedname)) {
        includeComp = false
      }

      // Additional condition for selectedPid
      if (selectedpid && !comp.pid.includes(selectedpid)) {
        includeComp = false
      }

      // Additional condition for selectedRegion
      if (selectedregion && !comp.region.toLowerCase().includes(selectedregion)) {
        includeComp = false
      }

      if (includeComp) {
        comps.push(comp)
        competitions.push({
          providerId: parseInt(comp.provider_id),
          competitionId: parseInt(comp.pid),
          regionId: parseInt(comp.region_id),
          sportId: parseInt(comp.sport_id),
          region: comp.region,
          sport: comp.sport,
          name: comp.name,
          sort: 100000
        })
      }
    }

    setFilteredcopy(competitions)
    setData(comps)

    return true
  }

  const prefiltercopyParticipants = async () => {
    sleep(2000)
    setCopiedParticipants(false)
    // Initialize filter variables
    let selectedname = selectedName ? selectedName.filterVal.toLowerCase() : ''
    let selectedpid = Selectedpid ? parseInt(Selectedpid.filterVal, 10) : null
    let selectedregion = selectedRegion ? selectedRegion.filterVal.toLowerCase() : ''
    let selectedid = selectedSport.filterVal
    let selecteddate = selectedDate
    let comps = []
    let competitions = []
    for (let comp of tableData) {
      let includeComp = true

      // Existing conditions
      if (selectedDate == 1) {
        if (!(comp.sport_id == selectedid && comp.is_excluded != 0)) {
          includeComp = false
        }
      } else {
        if (selectedOperator == '>') {
          if (!(comp.sport_id == selectedid && comp.is_excluded != 0 && comp.created_tmstmp > selecteddate)) {
            includeComp = false
          }
        } else {
          // selectedOperator == '<'
          if (!(comp.sport_id == selectedid && comp.is_excluded != 0 && comp.created_tmstmp < selecteddate)) {
            includeComp = false
          }
        }
      }

      // Additional condition for selectedName
      if (selectedname && !comp.name.toLowerCase().includes(selectedname)) {
        includeComp = false
      }

      // Additional condition for selectedPid
      if (selectedpid && !comp.pid.includes(selectedpid)) {
        includeComp = false
      }

      // Additional condition for selectedRegion
      if (selectedregion && !comp.region.toLowerCase().includes(selectedregion)) {
        includeComp = false
      }

      if (includeComp) {
        comps.push(comp)
        competitions.push({
          providerId: parseInt(comp.provider_id),
          competitionId: parseInt(comp.pid),
          regionId: parseInt(comp.region_id),
          sportId: parseInt(comp.sport_id),
          region: comp.region,
          sport: comp.sport,
          name: comp.name,
          sort: 100000
        })
      }
    }

    setFilteredcopyParticipants(competitions)
    setData(comps)

    return true
  }

  const preFilterData = async (allfilter, matchedfilter, data) => {
    sleep(2000)
    // if (data) {
    //   setAllData([])
    // }

    let dataToFilter = data ? data : allTableData

    let filteredData = []
    for (const key in dataToFilter) {
      if (Object.hasOwnProperty.call(dataToFilter, key)) {
        const line = dataToFilter[key]
        if (allfilter == false) {
          if (matchedfilter == true) {
            if (line.uni_id && line.uni_id != '' && line.uni_id != ' ') filteredData.push(line)
          } else {
            if (!line.uni_id || line.uni_id == '' || line.uni_id == ' ') filteredData.push(line)
          }
        } else {
          filteredData.push(line)
        }
      }
    }
    setData(filteredData)
    //console.log(filteredData)
    return true
  }

  function afterFilter(newResult, newFilters) {
    //console.log(newResult)
    setFilteredcounter(newResult.length)

    setSelectedSport(newFilters.sport_id)
    setSelectedRegion(newFilters.region)
    setSelectedpid(newFilters.pid)
    setSelectedName(newFilters.name)

    //console.log(newFilters)
  }

  function findcomps() {
    // find all comps from provider tableData with selectedSport
  }

  const clearToggleButtons = async () => {
    setMatched(false)
    setFilterAll(false)
  }

  const dragMouseDown = e => {
    e = e || window.event
    e.preventDefault()
    pos3 = e.clientX
    pos4 = e.clientY
    document.onmouseup = closeDragElement
    document.onmousemove = elementDrag
  }

  const elementDrag = e => {
    e = e || window.event
    e.preventDefault()

    pos1 = pos3 - e.clientX
    pos2 = pos4 - e.clientY
    pos3 = e.clientX
    pos4 = e.clientY

    // set element to move
    let element = null

    switch (e.target.textContent) {
      case 'Competition Results':
        element = document.getElementById('results')
        break
      case 'Participant Results':
        element = document.getElementById('results-participants')
        break
      default:
        break
    }

    if (element) {
      // set the element's new position:
      element.style.top = element.offsetTop - pos2 + 'px'
      element.style.left = element.offsetLeft - pos1 + 'px'
    } else return
  }

  const closeDragElement = () => {
    document.onmouseup = null
    document.onmousemove = null
  }

  const handleMatchedFilter = async () => {
    setClickedPrepareCopyParticipants(false)
    setClickedPrepareCopy(false)
    clearToggleButtons()
    setMatched(true)
    await preFilterData(false, true)
  }

  const handleCopyFilter = async () => {
    if (isPrepareCopyActive) {
      setIsPrepareCopyActive(false)
      //handleFilterAll()
    } else {
      setClickedPrepareCopy(true)
      setIsPrepareCopyActive(true)
      await prefiltercopy()
    }
    //console.log(tableData)
  }

  const handleCopyFilterParticipants = async () => {
    if (isPrepareCopyParticipantsActive) {
      setIsPrepareCopyParticipantsActive(false)
      //handleFilterAll()
    } else {
      setClickedPrepareCopyParticipants(true)
      setIsPrepareCopyParticipantsActive(true)
      await prefiltercopyParticipants()
    }
  }

  const handleUnmatchedFilter = async () => {
    setClickedPrepareCopyParticipants(false)
    setClickedPrepareCopy(false)
    clearToggleButtons()
    setMatched(false)
    await preFilterData(false, false)
  }

  const handleFilterAll = async () => {
    clearToggleButtons()
    setFilterAll(true)
    setClickedPrepareCopyParticipants(false)
    setClickedPrepareCopy(false)
    await preFilterData(true, false)
  }

  const handleResultsModal = event => {
    event.preventDefault()
    setResultsModal(false)
  }

  const handleResultsModalParticpant = event => {
    event.preventDefault()
    setResultsModalParticpant(false)
  }

  const creteFilters = () => {
    let sportFilters = {}

    for (let sport of sports) sportFilters[sport.id] = sport.name

    setSportFilters(sportFilters)
  }

  const updateDate = endDate => {
    setSelectedDate(endDate ? new Date(endDate).getTime() : 0)
    //if ((endDate, customFilterFunc)) customExternalFilter(customFilterFunc, endDate ? endDate : '')
    if ((endDate, customFilterFunc)) customExternalFilter(customFilterFunc, { number: endDate ? new Date(endDate).getTime() : 0, comparator: selectedOperator })
  }

  const updateOperator = operator => {
    let operatorSymbol = ''
    if (operator.label == 'After') operatorSymbol = '>'
    if (operator.label == 'Before') operatorSymbol = '<'
    setSelectedOperator(operatorSymbol)
    if ((selectedDate, customFilterFunc)) customExternalFilter(customFilterFunc, { number: selectedDate ? new Date(selectedDate).getTime() : 0, comparator: operatorSymbol })
  }

  useEffect(() => {
    if (selectedSport && selectedSport.filterVal !== undefined && providerId != 0) {
      //console.log(selectedSport)
      setDisabled(false)
      setDisabledparticipants(false)
    } else {
      setIsPrepareCopyActive(false)
      setDisabled(true)
      setIsPrepareCopyParticipantsActive(false)
      setDisabledparticipants(true)
    }
  }, [selectedSport, providerId])

  useEffect(() => {
    if (ClickedPrepareCopy == true) {
      if (selectedSport || selectedDate || selectedRegion || Selectedpid || selectedName) {
        prefiltercopy()
      }
    }
  }, [selectedSport, selectedDate, selectedRegion, Selectedpid, selectedName, ClickedPrepareCopy])

  useEffect(() => {
    if (ClickedPrepareCopyParticipants == true) {
      if (selectedSport || selectedDate || selectedRegion || Selectedpid || selectedName) {
        prefiltercopyParticipants()
      }
    }
  }, [selectedSport, selectedDate, selectedRegion, Selectedpid, selectedName, ClickedPrepareCopyParticipants])

  useEffect(() => {
    if (sports) creteFilters()
  }, [sports])

  useEffect(() => {
    fetchData(controller)
  }, [providerId])

  useEffect(() => {
    fetchData(controller)
  }, [forceUpdate])

  useEffect(() => {
    return () => {
      controller.abort()
    }
  }, [])

  return (
    <>
    <CompetitionUpdate showModal={showUpdateModal} competition={selectedCompetition} signalCloseModal={handleUpdateCompetitionCloseModal} />
    
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <Container fluid>
        <Row>
          <Col md="6">
            <Card id="results" style={{ display: ResultsModal ? '' : 'none' }}>
              <Card.Header>
                <Row>
                  <Col md="11">
                    <Card.Title as="h6" onMouseDown={dragMouseDown} name="error-results-competition-holder" style={{ cursor: 'move' }}>
                      Competition Results
                    </Card.Title>
                  </Col>
                  <Col md="1" style={{ marginTop: '-14px', textAlign: 'right' }}>
                    <Button
                      className="btn-simple btn-link p-1"
                      type="button"
                      variant="danger"
                      onClick={event => {
                        handleResultsModal(event)
                      }}
                    >
                      <i className="fas fa-times"></i>
                    </Button>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0" style={{ display: 'flex', overflow: 'auto' }}>
                <Col md="12">
                  <div className="results-font">
                    <div className="results-width">{errorResults[0] || successResults[0] ? '' : 'No error results found.'}</div>
                    <div className="results-flex">
                      <a href="#first-success-result" className="success-results">
                        Number of successful results: {successResults.length}
                      </a>
                      <a href="#first-error-result" className="error-results">
                        Number of error results: {errorResults.length}
                      </a>
                    </div>
                    <div className="results-container">
                      <table>
                        <thead>
                          <tr>
                            <th>Comp ID</th>
                            <th>Provider ID</th>
                            <th>Sport ID</th>
                            <th>Message</th>
                          </tr>
                        </thead>
                        <tbody>
                          {errorResults.map((item, index) => (
                            <tr key={index} className="error-item" id={index === 0 ? 'first-error-result' : undefined}>
                              <td>{item.comp_id}</td>
                              <td>{item.provider_id}</td>
                              <td>{item.sport_id}</td>
                              <td>{item.reason}</td>
                            </tr>
                          ))}
                          {successResults.map((item, index) => (
                            <tr key={`success-${index}`} className="success-item" id={index === 0 ? 'first-success-result' : undefined}>
                              <td>{item.comp_id}</td>
                              <td>{item.provider_id}</td>

                              <td>{item.sport_id}</td>
                              <td>{item.reason}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </Col>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      <Container fluid>
        <Row>
          <Col md="6">
            <Card id="results-participants" style={{ display: ResultsModalParticpant ? '' : 'none' }}>
              <Card.Header>
                <Row>
                  <Col md="11">
                    <Card.Title as="h6" onMouseDown={dragMouseDown} name="error-results-participants-holder" style={{ cursor: 'move' }}>
                      Participant Results
                    </Card.Title>
                  </Col>
                  <Col md="1" style={{ marginTop: '-14px', textAlign: 'right' }}>
                    <Button
                      className="btn-simple btn-link p-1"
                      type="button"
                      variant="danger"
                      onClick={event => {
                        handleResultsModalParticpant(event)
                      }}
                    >
                      <i className="fas fa-times"></i>
                    </Button>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0" style={{ display: 'flex', overflow: 'auto' }}>
                <Col md="12">
                  <div className="results-font">
                    <div className="results-width">{errorResultsParticipants[0] || successResultsParticipants[0] ? '' : 'No error results found.'}</div>
                    <div className="results-flex">
                      <a href="#first-success-result" className="success-results">
                        Number of successful results: {successResultsParticipants.length}
                      </a>
                      <a href="#first-error-result" className="error-results">
                        Number of error results: {errorResultsParticipants.length}
                      </a>
                    </div>
                    <div className="results-container">
                      <table>
                        <thead>
                          <tr>
                            <th>Comp ID</th>
                            <th>Provider ID</th>
                            <th>Sport ID</th>
                            <th>Message</th>
                          </tr>
                        </thead>
                        <tbody>
                          {errorResultsParticipants.map((item, index) => (
                            <tr key={index} className="error-item" id={index === 0 ? 'first-error-result' : undefined}>
                              <td>{item.comp_id}</td>
                              <td>{item.provider_id}</td>
                              <td>{item.sport_id}</td>
                              <td>{item.reason}</td>
                            </tr>
                          ))}
                          {successResultsParticipants.map((item, index) => (
                            <tr key={`success-${index}`} className="success-item" id={index === 0 ? 'first-success-result' : undefined}>
                              <td>{item.comp_id}</td>
                              <td>{item.provider_id}</td>

                              <td>{item.sport_id}</td>
                              <td>{item.reason}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </Col>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      <div style={{ display: 'flex', marginBottom: '20px' }}>
        <div md="2">
          <label>Created:</label>
          <CustomSelect
            options={[
              { id: '1', name: 'After' },
              { id: '2', name: 'Before' }
            ]}
            selectedItem={'{ "id": "1", "name": "After", "value": "1", "label": "After" }'}
            setName="operators"
            updateSelection={updateOperator}
          ></CustomSelect>
          <DateSelect setParentData={updateDate} showTime={false} />
        </div>

        <div style={{ display: 'flex', width: '100%' }}>
          <div style={{ width: '100%', textAlign: 'right' }}>
            <ButtonGroup size="sm" aria-label="Basic example">
              <Button variant={filterAll ? 'success' : 'danger'} onClick={handleFilterAll}>
                All
              </Button>
              <Button variant={matched ? 'success' : 'danger'} onClick={handleMatchedFilter}>
                Matched
              </Button>
              <Button variant={filterAll ? 'danger' : !matched ? 'success' : 'danger'} onClick={handleUnmatchedFilter}>
                Unmatched
              </Button>
            </ButtonGroup>
            <TableCounters all={allcounter} filtered={filteredcounter}></TableCounters>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', marginTop: '20px' }}>
              <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}>
                <div>Competitions:</div>
                <Button style={{ width: '100px', height: '30px', padding: '5px', fontSize: '12px' }} disabled={disabled} onClick={handleCopyFilter} className={disabled ? 'precopy-button-disabled' : isPrepareCopyActive ? 'precopy-button-active' : 'precopy-button-ready'}>
                  {isPrepareCopyActive ? 'Cancel' : 'Prepare Copy'}
                </Button>
                {isPrepareCopyActive && (
                  <Button style={{ width: '100px', height: '30px', padding: '5px', fontSize: '12px' }} disabled={disabled} onClick={handleCopy} className={disabled ? 'copy-button-disabled' : isPrepareCopyActive ? 'copy-button-active' : 'copy-button-ready'}>
                    Copy
                  </Button>
                )}
                {copied && (
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button style={{ width: '100px', height: '30px', padding: '5px', fontSize: '12px' }} disabled={prevdisabled} onClick={handleprev} className={prevdisabled ? 'results-button-disabled' : isPrepareCopyActive ? 'results-button-active' : 'results-button-ready'}>
                      Show results
                    </Button>
                  </div>
                )}
              </div>
              <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}>
                <div>Participants:</div>
                <Button style={{ width: '100px', height: '30px', padding: '5px', fontSize: '12px' }} disabled={disabledparticipants} onClick={handleCopyFilterParticipants} className={disabledparticipants ? 'precopy-button-disabled' : isPrepareCopyParticipantsActive ? 'precopy-button-active' : 'precopy-button-ready'}>
                  {isPrepareCopyParticipantsActive ? 'Cancel' : 'Prepare Copy'}
                </Button>
                {isPrepareCopyParticipantsActive && (
                  <Button style={{ width: '100px', height: '30px', padding: '5px', fontSize: '12px' }} disabled={disabledparticipants} onClick={handleCopyParticipants} className={disabledparticipants ? 'copy-button-disabled' : isPrepareCopyParticipantsActive ? 'copy-button-active' : 'copy-button-ready'}>
                    Copy
                  </Button>
                )}
                {copiedParticipants && (
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button style={{ width: '100px', height: '30px', padding: '5px', fontSize: '12px' }} disabled={prevdisabledparticipants} onClick={handleprevparticipants} className={prevdisabledparticipants ? 'results-button-disabled' : isPrepareCopyParticipantsActive ? 'results-button-active' : 'results-button-ready'}>
                      Show results
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <BootstrapTable bootstrap4 keyField="id" data={tableData} columns={columns} pagination={paginationFactory({ sizePerPage: 10 })} filter={filterFactory({ afterFilter })} cellEdit={cellEditFactory({ mode: 'click', beforeSaveCell: changeCellValue })} />
    </>
  )
}
export default ProviderComps
