import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter'
import cellEditFactory from 'react-bootstrap-table2-editor'
import Swal from 'sweetalert2'
import NotificationAlert from 'react-notification-alert'
import UniCompetitionUpdate from 'components/Modals/UpdateUniComps'
import { getData, delData, putData } from 'utils/server'
import { getAuthorizationHeader, notificationOptions, copyToClipboard } from 'utils/helpers'
import UniTranslation from 'components/Modals/UniTranslation'
import TableCounters from 'components/UniObject/TableCounters'
import LeaderProviderButton from 'components/Inputs/leaderProviderButton'

function UniComps({ forceUpdate, sports, regions, informParent, downloadcsv, informer }) {
  const controller = new AbortController()

  const notificationAlertRef = React.useRef(null)
  const [tableData, setData] = useState([])
  const [allcounter, setAllCounter] = useState(0)
  const [filteredcounter, setFilteredcounter] = useState(0)
  const [showModal, setShowModal] = useState(false)
  const [sportFilters, setSportFilters] = useState({})
  const [regionFilters, setRegionFilters] = useState({})
  const [selectedCompId, setSelectedCompId] = useState(null)
  const [selectedCompName, setSelectedCompName] = useState('')
  const [selectedCompetition, setSelectedCompetition] = useState({})

  const [showUpdateModal, setShowUpdateModal] = useState(false)
  const [sport, setCompetitionSport] = useState(null)
  const [region, setCompetitionRegion] = useState(null)
  const [providers, setProviders] = useState([])

  const handleDeleteClick = row => {
    Swal.fire({ title: `Delete Competition ${row.name}?`, showDenyButton: true, showConfirmButton: true, confirmButtonText: 'Yes, delete it!', denyButtonText: 'Cancel', icon: 'warning', confirmButtonColor: '#87cb16', denyButtonColor: '#ff4a55' }).then(async result => {
      if (result.isConfirmed) {
        let result = await delData(`/api/unis/competitions/${row.id}`, { Authorization: getAuthorizationHeader() })
        if (result != null) {
          Swal.fire('Deleted!', `Competition ${row.name} has been deleted.`, 'success')
          fetchData(controller)
          informParent({ refresh: true })
        } else {
          Swal.fire('Error!', `Something went wrong.`, 'error')
        }
      }
    })
  }

  const downloadCsv = async controller => {
    let csvData = _.sortBy(tableData, ['region, name'])
    let csv = 'data:text/csv;charset=utf-8,'
    csv += 'Sport; Region; Name\n'
    csvData.forEach(row => {
      csv += `${row.sport};${row.region};${row.name}\n`
    })
    let encodedUri = encodeURI(csv)
    let link = document.createElement('a')
    link.setAttribute('href', encodedUri)
    link.setAttribute('download', 'uni_competitions.csv')
    document.body.appendChild(link)
    link.click()
    informer({ downloadcsv: true })
  }

  const handleEditClick = row => {
    //console.log(row)
    setSelectedCompetition(row)
    setSelectedCompId(row.id)
    setCompetitionSport({ value: row.sport_id, label: row.sport })
    setCompetitionRegion({ value: row.region_id, label: row.region })

    showUpdateModal ? setShowUpdateModal(false) : setShowUpdateModal(true)
  }

  const handleUpdateCompetitionCloseModal = update => {
    setShowUpdateModal(false)
    if (update) {
      fetchData(controller)
      //informParent({ refresh: true })
    }
  }

  const handleFixturesClick = row => {
    informParent({ fixtures: row })
  }

  const handleParticipantsInfo = row => {
    informParent({ participants: row })
  }

  const handleCloseModal = () => {
    setShowModal(false)
  }

  const handleMatchingInfoUniCompetitions = async (competitions, controller) => {
    let result = await getData(`/api/unis/competitions/${competitions.id}/relations`, { Authorization: getAuthorizationHeader() }, null, { signal: controller.signal })

    //console.log(result.data)

    let htmlText = '<div style="min-width:150px;text-align: left;"><ul>'

    let name_width = 160
    let provider_width = 140

    for (const element of result.data) {
      name_width = Math.max(name_width, element.name.length * 10)
      provider_width = Math.max(provider_width, element.provider.length * 10)
    }

    // Construct the HTML text for each element
    for (const element of result.data) {
      const minWidthPid = Math.max(element.pid.toString().length * 8, provider_width)
      const minWidthRegion = Math.max(element.region.length * 7, 150) // Increase the minimum width for region

      htmlText += `<li style="border-bottom: 1px solid;width: max-content; display: flex; align-items: stretch;">
      <div class="rowCell" style="text-align: left;padding: 8px;min-width: ${minWidthPid}px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">${element.pid}</div>
      <div class="rowCell" style="text-align: left;padding: 8px;min-width: ${minWidthRegion}px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">${element.region}</div>
      <div class="rowCell" style="text-align: left;padding: 8px;min-width: 150px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">${element.sport}</div>

      <div class="rowCell ${element.provider}Provider" style="text-align: center; padding: 8px; width: ${provider_width}px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">${element.provider}</div>
      <div class="rowCell" style="display: flex; justify-content: center; align-items: center; padding: 8px; width: ${name_width}px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">${element.name}</div>
    </li>`
    }

    htmlText += '</ul></div>'

    Swal.fire({
      title: `Matched Competitions:`,
      customClass: 'swal-wide',
      html: htmlText,
      showDenyButton: false,
      showConfirmButton: true,
      confirmButtonText: 'OK',
      denyButtonText: 'Cancel',
      icon: 'info',
      confirmButtonColor: '#87cb16',
      denyButtonColor: '#ff4a55'
    }).then(async result => {
      if (result.isConfirmed) {
        // Handle confirmation
      }
    })
  }
  const handleTranslationClick = row => {
    setSelectedCompId(row.id)
    setSelectedCompName(row.name)
    setShowModal(true)
  }

  const changeCellValue = (oldValue, newValue, row, column, done) => {
    if (newValue == '') done(false)
    const { region, sport, sport_id, region_id, sort } = row
    putData(`/api/unis/competitions/${row.id}`, { name: newValue, region: region, sport: sport, sport_id: sport_id, region_id: region_id, sort: sort }, { Authorization: getAuthorizationHeader() }).then(response => {
      if (response == null) {
        notificationAlertRef.current.notificationAlert(notificationOptions('Error! Cannot edit record.', 'danger'))
        done(false)
      } else {
        notificationAlertRef.current.notificationAlert(notificationOptions(`Competition ${row.id} has been Updated.`, 'success'))
        done(true)
      }
    })
    return { async: true }
  }

  const fetchData = async controller => {
    if (sports.length == 0 && regions.length == 0) return
    let response = await getData('/api/unis/competitions', { Authorization: getAuthorizationHeader() }, null, { signal: controller.signal })
    let tableData = []
    if (response != null) tableData = response.data
    setAllCounter(response.data.length)
    setData(tableData)
    informer({ downloadcsv: true })
  }

  const createFilters = () => {
    let sportFilters = {}
    let regionFilters = []
    for (let sport of sports) sportFilters[sport.id] = sport.name
    for (let region of regions) regionFilters.push({ value: region.id, label: region.name })
    setSportFilters(sportFilters)
    setRegionFilters(regionFilters)
  }

  const fetchProviders = async controller => {
    try {
      let providers_response = await getData('/api/providers', { Authorization: getAuthorizationHeader() }, null, { signal: controller.signal })
      setProviders(providers_response.data)
    } catch (error) {
      console.error('Error fetching providers:', error)
    }
  }

  function afterFilter(newResult, newFilters) {
    setFilteredcounter(newResult.length)
  }

  useEffect(() => {
    if (sports && regions) createFilters()
  }, [sports, regions])

  useEffect(() => {
    if (downloadcsv) downloadCsv(controller)
  }, [downloadcsv])

  useEffect(() => {
    if (forceUpdate) fetchData(controller)
  }, [forceUpdate])

  useEffect(() => {
    fetchProviders(controller)
    return () => controller.abort()
  }, [])

  useEffect(() => {
    return () => {
      controller.abort()
    }
  }, [])

  const columns = [
    {
      dataField: 'id',
      text: 'UNI ID',
      headerStyle: { width: '5vw' },
      sort: true,
      formatter: (cellContent, row) => (
        <>
          <div onClick={e => copyToClipboard(e, cellContent, notificationAlertRef)} style={{ cursor: 'copy' }}>
            <p>{cellContent}</p>
          </div>
        </>
      ),
      filter: textFilter()
    },
    {
      dataField: 'sport_id',
      text: 'Sport',
      headerStyle: { width: '6vw' },
      formatter: cell => sportFilters[cell],
      filter: selectFilter({
        options: sportFilters
      }),
      editable: false
    },
    {
      dataField: 'region_id',
      text: 'Region',
      formatter: cell => (regionFilters.find(opt => opt.value === cell) ? regionFilters.find(opt => opt.value === cell).label : ''),
      filter: selectFilter({
        options: regionFilters
      }),
      editable: false
    },
    {
      dataField: 'name',
      text: 'Name',
      filter: textFilter(),
      editable: true,
      sort: true
    },
    {
      dataField: 'dm1',
      isDummyField: true,
      editable: false,
      text: 'Actions',
      formatter: (cellContent, row) => (
        <>
          <Button
            className="btn-simple btn-link p-1"
            type="button"
            title="Show Matched Competitions"
            //variant={row.matched.length > 0 ? 'dark' : 'secondary'}
            //disabled={row.matched.length > 0 ? false : true}
            onClick={() => {
              handleMatchingInfoUniCompetitions(row, controller)
            }}
            //title={'Matched Markets: ' + row.matched.length}
          >
            <i className="fas fa fa-info"></i>
          </Button>
          <Button
            title="Edit Translations"
            className="btn-simple btn-link p-1"
            type="button"
            variant="warning"
            onClick={() => {
              handleTranslationClick(row)
            }}
          >
            <i className="fas fa-comments"></i>
          </Button>
          <Button
            title="Edit competition"
            className="btn-simple btn-link p-1"
            type="button"
            variant="info"
            onClick={() => {
              handleEditClick(row)
            }}
          >
            <i className="fas fa-edit"></i>
          </Button>
          <Button
            title="Show Matched Fixtures"
            className="btn-simple btn-link p-1"
            type="button"
            variant="success"
            onClick={() => {
              handleFixturesClick(row)
            }}
          >
            <i className="fas fa-tasks"></i>
          </Button>
          <Button
            title="Show Participants"
            className="btn-simple btn-link p-1"
            type="button"
            onClick={() => {
              handleParticipantsInfo(row)
            }}
          >
            <i className="fas fa-user"></i>
          </Button>
          <LeaderProviderButton row={row} fetchData={fetchData} informParent={informParent} allProviders={providers} />
          <Button
            title="Delete Competition"
            className="btn-simple btn-link p-1"
            type="button"
            variant="danger"
            onClick={() => {
              handleDeleteClick(row)
            }}
          >
            <i className="fas fa-times"></i>
          </Button>
        </>
      )
    }
  ]
  return (
    <>
      <UniTranslation showModal={showModal} entityId={selectedCompId} entityName={selectedCompName} entityType={'competitions'} entityKey={'competition_id'} signalCloseModal={handleCloseModal} />
      <UniCompetitionUpdate showModal={showUpdateModal} competition={selectedCompetition} regions={regions} sports={sports} sport={sport} region={region} signalCloseModal={handleUpdateCompetitionCloseModal} />
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <TableCounters all={allcounter} filtered={filteredcounter}></TableCounters>
      <BootstrapTable bootstrap4 keyField="id" data={tableData} columns={columns} pagination={paginationFactory({ sizePerPage: 10 })} filter={filterFactory({ afterFilter })} cellEdit={cellEditFactory({ mode: 'click', beforeSaveCell: changeCellValue })} />
    </>
  )
}
export default UniComps
