import DataSelect from './generalDataSelect'
import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import { postData } from 'utils/server'
import Swal from 'sweetalert2'
import { getAuthorizationHeader } from 'utils/helpers'
import { Button } from 'react-bootstrap'

function leaderProviderButton({ row, fetchData, informParent, allProviders }) {
  const controller = new AbortController()
  const [selectedProvider, setSelectedProvider] = useState({ value: 0, label: 'All providers' })
  const existingLeaderProviderId = row.leader_provider_id

  const updateSelectedProvider = provider => {
    setSelectedProvider(provider)
  }

  const handleProviderLeaderClick = async () => {
    let selectedProviderId = null

    Swal.fire({
      title: `Select a leader provider for competition: ${row.name}`,
      html: '<div id="provider-select-container"></div>',
      showDenyButton: true,
      showConfirmButton: true,
      confirmButtonText: 'Yes, select it!',
      denyButtonText: 'Cancel',
      icon: 'info',
      confirmButtonColor: '#87cb16',
      denyButtonColor: '#ff4a55',
      didOpen: () => {
        const htmlContainer = document.querySelector('.swal2-html-container')
        if (htmlContainer) {
          htmlContainer.style.display = 'inline-table'
          htmlContainer.style.zIndex = '1000'
        }
        ReactDOM.render(
          <DataSelect
            data={allProviders}
            updateItem={provider => {
              updateSelectedProvider(provider)
              selectedProviderId = provider?.value
            }}
            selectedItem={existingLeaderProviderId ? allProviders.find(p => p.id === existingLeaderProviderId) : selectedProvider}
            placeholder="Select Provider..."
          />,
          document.getElementById('provider-select-container')
        )
      },
      preConfirm: () => {
        if (!selectedProviderId) {
          Swal.showValidationMessage('Please select a provider!')
          return false
        }
        return true
      }
    }).then(async result => {
      if (result.isConfirmed && selectedProviderId) {
        let payload = {
          providerId: selectedProviderId
        }
        let response = await postData(`/api/unis/competitions/${row.id}/leader-provider`, payload, { Authorization: getAuthorizationHeader() }, null, { signal: controller.signal })

        if (response != null) {
          Swal.fire({ title: `A leader provider has been selected for the competition: ${row.name}.`, icon: 'success' })
          fetchData(controller)
          informParent({ refresh: true })
        } else {
          console.error(error)
          Swal.fire({ title: `Error!Something went wrong.`, icon: 'danger' })
        }
      }
    })
  }

  return (
    <Button title="Select Leader Provider" className="btn-simple btn-link p-1" type="button" variant={existingLeaderProviderId ? 'success' : 'info'} onClick={handleProviderLeaderClick}>
      <i className="fas fa-cogs"></i>
    </Button>
  )
}

export default leaderProviderButton
